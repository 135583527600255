<template lang="pug">
  .access-setting-container 
    .container-fluid
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.access_settings.address')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )

      .row.mb-1
        .postal-code
          BFormInput(
            type="text"
            name="postal-code"
            :class="{ invalid: $v.accessSetting.postal_code.$error }"
            v-model="accessSetting.postal_code"
            :placeholder="$t('company_system.shop_settings.shop_form.access_settings.postal_code')"
            :state="null"
            aria-describedby="postal-code"
            :formatter="postalCodeFormatter"
            ref="postalCode"
          )
      .row.mb-1
        .col-md-12.col-lg-9.col-xxl-6
          AppDropdown.airports(
            close-on-select
            title-key="name"
            value-key="id"
            :value="selectedPrefecture"
            :items="translatedPrefectures"
            @select="selectPrefecture"
          )

      .row.mb-1
        .col-md-12.col-lg-9.col-xxl-6
          BFormInput(
            type="text"
            name="address"
            :class="{ invalid: $v.accessSetting.address.$error }"
            :value="accessSetting.address"
            :placeholder="$t('company_system.shop_settings.shop_form.access_settings.address')"
            :state="null"
            aria-describedby="address"
            ref="address"
          )

      .row
        .col-md-12.col-lg-9.col-xxl-6
          .map
            GmapMap(
              id="map"
              ref="Map"
              :center="center"
              :zoom="15"
              @click="mapClick"
              style="width: 100%; height: 100%;"
            )
              GmapMarker(v-for="marker in markers" :position="marker" :key="marker.lng")

          
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.access_settings.phone_number')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )

      .row.access-setting-form-field
        .phone-number 
          BFormInput(
            type="text"
            name="phone-number"
            :class="{ invalid: $v.accessSetting.phone_number.$error }"
            v-model="accessSetting.phone_number"
            :placeholder="$t('company_system.shop_settings.shop_form.access_settings.phone_number')"
            :state="null"
            aria-describedby="phone-number"
            :formatter="phoneNumberFormatter"
          )

      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.access_settings.fax')"
        )
        
      .row.access-setting-form-field
        .fax 
          BFormInput(
            type="text"
            name="fax"
            v-model="accessSetting.fax_number"
            :placeholder="$t('company_system.shop_settings.shop_form.access_settings.fax')"
            :state="null"
            aria-describedby="fax"
            :formatter="phoneNumberFormatter"
          )
              
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.access_settings.reservation_notification_email')"
          :class="{ invalid: $v.accessSetting.reservation_emails.$error }"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          .emails
            .email.d-flex.align-items-center(
              v-for="(email, index) in $v.accessSetting.reservation_emails.$each.$iter"
              :key="index"
            )
              BFormInput(
                type="text"
                :name="`notification-email-${index}`"
                :class="{ invalid: email.$error }"
                v-model="email.$model.value"
                :placeholder="$t('company_system.shop_settings.shop_form.access_settings.reservation_notification_email')"
                :state="null"
                :aria-describedby="`notification-email-${index}`"
              )
              AppIconButton.action-button(
                v-if="reservationEmailsNumber > 1"
                :class="{ disabled: false }"
                icon="trash-alt"
                @click="deleteEmail(index)"
              )
            AppAddNewButton.add-new-button.m-r-6(
              @click="addEmail"
              :disabled="reservationEmailsNumber >= 5"
            )
            span.email-add-info {{ $t('company_system.shop_settings.shop_form.access_settings.reservation_notification_email_label') }}

      .row.label
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.contact_email')"
            required
            :required-label="$t('company_system.shop_settings.shop_form.required')"
          )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          .contact-email 
            BFormInput(
              type="text"
              name="contact-email"
              :class="{ invalid: $v.accessSetting.contact_email.$error }"
              v-model="accessSetting.contact_email"
              :placeholder="$t('company_system.shop_settings.shop_form.access_settings.contact_email')"
              :state="null"
              aria-describedby="contact-email"
            )

      .row.label
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.nearest_airport')"
          )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          AppDropdown.airports(
            close-on-select
            title-key="name"
            value-key="id"
            :value="selectedAirport"
            :items="translatedAirports"
            @select="selectAirport"
          )
      
      .row.label
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.nearest_shinkansen_station')"
          )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          .shinkansen
            BFormInput(
              type="text"
              name="shinkansen"
              v-model="accessSetting.shinkansen_station"
              :placeholder="$t('company_system.shop_settings.shop_form.access_settings.nearest_shinkansen_station')"
              :state="null"
              aria-describedby="shinkansen"
            )

      .row.label
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.nearest_station')"
          )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          .station
            BFormInput(
              type="text"
              name="station"
              v-model="accessSetting.station"
              :placeholder="$t('company_system.shop_settings.shop_form.access_settings.nearest_station')"
              :state="null"
              aria-describedby="station"
            )

      .row.label
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.access_information')"
          )

      .row.access-setting-form-field
        .col-md-12.col-lg-9.col-xxl-6
          .access-information
            textarea.form-control(
              v-model="accessSetting.access_information"
              type="text"
              :rows="3"
              :max-rows="10"
              :maxlength="1024"
              :placeholder="$t('company_system.shop_settings.shop_form.access_settings.access_information')"
            )
          .textarea-length {{ accessInformationLabel }}

      .row.label.mb-1
        .col-md-12.col-lg-9.col-xxl-6
          FormFieldLabel(
            :title="$t('company_system.shop_settings.shop_form.access_settings.transfer')"
          )
      
      .row
        .col-md-12.col-lg-9.col-xxl-6
          .shuttle.d-flex.align-items-center
            AppCheckbox(
              v-model="accessSetting.guest_pickup"
            )
            .shuttle_label {{ $t('company_system.shop_settings.shop_form.access_settings.shuttle_available') }}

      .row 
        .col-md-12.col-lg-9.col-xxl-6
          .transfer 
            .shuttle-availability
            .textarea
              textarea.form-control(
                v-model="accessSetting.guest_pickup_information"
                type="text"
                :rows="3"
                :max-rows="10"
                :maxlength="1024"
                :placeholder="$t('company_system.shop_settings.shop_form.access_settings.transfer')"
              )
            .textarea-length {{ transferInformationLabel }}
    Footer(
      delete-enabled
      @save="handleSave"
      @delete="$emit('remove-shop')"
    )
</template>

<script>
  // misc
  import { DESCRIPTION_MAX_LENGTH } from "./constants"
  import { EMAIL_REGEXP } from "@/config/constants"
  import IMask from "imask"
  import { extractTranslatedAttribute } from "@/helpers/common"
  import { find, get, map, isEmpty } from "lodash-es"

  const postalCodeMask = IMask.createMask({
    mask: "000 - 0000"
  })

  const phoneNumberMask = IMask.createMask({
    mask: /^(\+?([0-9]+[-]?)*)$/
  })

  const DEFAULT_LAT = 35.6894
  const DEFAULT_LNG = 139.76

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import airportsModule from "@/config/store/airports"
  import prefecturesModule from "@/config/store/prefectures"
  import accessSettingModule from "@/config/store/company_system/shops_settings/shops/access_settings"

  const validationsMixin = withValidations(({ required, minLength }) => ({
    accessSetting: {
      postal_code: { required },
      address: { required },
      phone_number: { required },
      contact_email: {
        required,
        emailCheck: value => isEmpty(value) || EMAIL_REGEXP.test(value)
      },
      reservation_emails: {
        required,
        minLength: minLength(1),
        $each: {
          value: {
            required,
            emailCheck: value => isEmpty(value) || EMAIL_REGEXP.test(value)
          }
        }
      }
    }
  }))

  const airportsMixin = withStoreModule(airportsModule, {
    name: "airports",
    readers: { airports: "items", airportsLoading: "loading" },
    actions: { fetchAirports: "FETCH_ITEMS" }
  })

  const prefecturesMixin = withStoreModule(prefecturesModule, {
    name: "prefectures",
    readers: { prefectures: "items", prefecturesLoading: "loading" },
    actions: { fetchPrefectures: "FETCH_ITEMS" }
  })

  const companiesShopAccessSettingMixin = withStoreModule(accessSettingModule, {
    resetState: true,
    name: "companiesShopAccessSetting",
    readers: { accessSetting: "item" },
    actions: {
      fetchAccessSetting: "FETCH_ITEM",
      updateAccessSetting: "UPDATE_ITEM"
    }
  })

  export default {
    props: {
      shopId: {
        type: Number,
        required: true
      }
    },

    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      Footer: () => import("./Footer")
    },

    mixins: [companiesShopAccessSettingMixin, airportsMixin, prefecturesMixin, validationsMixin, withScrollTop],

    data() {
      return {
        autocompleteOptions: {
          componentRestrictions: { country: "jp" },
          fields: ["geometry", "place_id", "name", "formatted_address", "address_components"],
          strictBounds: false
        },
        markers: []
      }
    },

    mounted() {
      this.fetchAirports()
      this.fetchPrefectures()
      this.fetchAccessSetting(this.shopId)
      this.$gmapApiPromiseLazy().then(() => {
        this.initAutocompletePostalCode(this.$refs.postalCode.$el)
        this.initAutocompleteAddress(this.$refs.address.$el)
      })
    },

    computed: {
      googleMaps() {
        return window.google.maps
      },

      translatedAirports() {
        return map(this.airports, airport => {
          return {
            id: airport.id,
            name: extractTranslatedAttribute(airport, "name")
          }
        })
      },

      translatedPrefectures() {
        return map(this.prefectures, prefecture => {
          return {
            id: prefecture.id,
            name: extractTranslatedAttribute(prefecture, "name"),
            rawName: prefecture.en_name.toLowerCase().replace(" prefecture")
          }
        })
      },

      selectedAirport() {
        return find(this.translatedAirports, ({ id }) => id === this.accessSetting.airport_id)
      },

      selectedPrefecture() {
        return find(this.translatedPrefectures, ({ id }) => id === this.accessSetting.prefecture_id)
      },

      center({ accessSetting: { longitude, latitude } }) {
        return {
          lat: latitude || DEFAULT_LAT,
          lng: longitude || DEFAULT_LNG
        }
      },

      reservationEmailsNumber() {
        return get(this.accessSetting, "reservation_emails.length") || 0
      },

      accessInformationLength() {
        return get(this.accessSetting, "access_information.length") || 0
      },

      accessInformationLabel() {
        return this.textLength(this.accessInformationLength, DESCRIPTION_MAX_LENGTH)
      },

      transferInformationLength() {
        return get(this.accessSetting, "guest_pickup_information.length") || 0
      },

      transferInformationLabel() {
        return this.textLength(this.transferInformationLength, DESCRIPTION_MAX_LENGTH)
      }
    },

    methods: {
      selectAirport({ id }) {
        this.accessSetting.airport_id = id
      },

      selectPrefecture({ id }) {
        this.accessSetting.prefecture_id = id
      },

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      initAutocompletePostalCode(element) {
        const autocomplete = new this.googleMaps.places.Autocomplete(element, {
          componentRestrictions: { country: "jp" },
          types: ["postal_code"],
          fields: ["geometry", "address_components"],
          strictBounds: false
        })
        this.googleMaps.event.clearInstanceListeners(element)
        this.googleMaps.event.addListener(autocomplete, "place_changed", () => {
          const place = autocomplete.getPlace()
          this.updateLocation(element.value, this.getPostalCode(place.address_components), place.geometry.location)
          this.updatePrefectureFromAddress(place.address_components)
        })
      },

      initAutocompleteAddress(element) {
        const autocomplete = new this.googleMaps.places.Autocomplete(element, this.autocompleteOptions)
        this.googleMaps.event.clearInstanceListeners(element)
        this.googleMaps.event.addListener(autocomplete, "place_changed", () => {
          const place = autocomplete.getPlace()
          if (place.geometry) {
            this.updateLocation(element.value, this.getPostalCode(place.address_components), place.geometry.location)
            this.updatePrefectureFromAddress(place.address_components)
          } else {
            this.searchPlace(place.name)
          }
        })
      },

      getPostalCode(address) {
        return find(address, ({ types }) => types.includes("postal_code"))?.long_name
      },

      updatePrefectureFromAddress(address) {
        const prefectureName = this.getPrefectureName(address)
        const prefecture = this.findPrefecture(prefectureName)
        this.selectPrefecture(prefecture ? prefecture : {})
      },

      findPrefecture(name) {
        const lowercasedName = name.toLowerCase()
        return find(this.translatedPrefectures, ({ rawName }) => rawName.includes(lowercasedName))
      },

      getPrefectureName(address) {
        return find(address, ({ types }) => types.includes("administrative_area_level_1"))?.long_name
      },

      changeLocation(lat, lng) {
        this.accessSetting.latitude = lat
        this.accessSetting.longitude = lng
        this.markers = [{ lat: lat, lng: lng }]
      },

      updateLocation(address, postalCode, location) {
        this.accessSetting.address = address
        this.accessSetting.postal_code = postalCode
        this.changeLocation(location.lat(), location.lng())
      },

      searchPlace(value) {
        const service = new this.googleMaps.places.AutocompleteService()
        service.getPlacePredictions({ ...this.autocompleteOptions, input: value }, results => {
          const placeService = new this.googleMaps.places.PlacesService(this.$refs.map.mapObject)
          if (results && results[0]) {
            placeService.getDetails({ placeId: results[0].place_id }, place => {
              this.updateLocation(
                place.formatted_address,
                this.getPostalCode(results[0].address_components),
                place.geometry.location
              )
              this.updatePrefectureFromAddress(results[0].address_components)
            })
          } else {
            this.changeLocation(null, null)
          }
        })
      },

      handleChange(type, value) {
        this[type] = value
      },

      postalCodeFormatter(value) {
        postalCodeMask.resolve(value)
        return postalCodeMask.value
      },

      phoneNumberFormatter(value) {
        phoneNumberMask.resolve(value)
        return phoneNumberMask.value
      },

      addEmail() {
        this.accessSetting.reservation_emails.push({ value: null })
      },

      deleteEmail(index) {
        this.accessSetting.reservation_emails.splice(index, 1)
      },

      mapClick(event) {
        const geocoder = new this.googleMaps.Geocoder()
        geocoder.geocode({ latLng: event.latLng, region: "jp" }, (results, status) => {
          if (status === this.googleMaps.GeocoderStatus.OK) {
            const result = results[0]
            if (result) {
              this.updateLocation(result.formatted_address, this.getPostalCode(result.address_components), event.latLng)
            } else {
              alert("Address not found")
            }
          } else {
            alert("Geocoder failed due to: " + status)
          }
        })
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("refresh-shop")
        })
      },

      async sendRequest() {
        await this.updateAccessSetting({ shopId: this.shopId, access_setting: this.accessSetting })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/company-system.sass"

  .access-setting-container
    .row
      &.label
        +form-label

      ::v-deep
        .required
          margin-left: 8px

        span
          &.invalid
            color: $default-red

        input
          &.invalid
            +default-invalid-input

        > div
          padding: 0

        .app-select
          padding: 0
          .dropdown-toggle
            height: 38px
            border-color: #ccc

    .postal-code
      width: 100px
      .form-control
        padding: 0
        padding-left: 7px

    .map
      width: 100%
      height: 320px
      background: grey
      margin-bottom: 24px

    .access-setting-form-field
      margin-bottom: 24px
    .phone-number, .fax
      width: 160px

    .textarea-length
      font-size: 12.5px
      font-weight: 500
      color: $default-gray

    .email
      margin-bottom: 10px
      button
        margin-left: 10px
        ::v-deep
          svg
            margin-right: 0

    .shuttle
      margin-bottom: 8px

      &_label
        margin-left: 4px
      ::v-deep
        .app-checkbox
          padding: 0
</style>
